<script setup lang="ts">
// часть, отвечающая за авторизацию при возврате на страницу из ТГ
const route = useRoute();
const auth = useAuth();
const { isLoggedIn } = storeToRefs(auth);

async function goToHome() {
  const query = route.name === 'auth-register' ? { welcome: 1 } : undefined;
  await navigateTo({
    path: DEFAULT_HOME,
    query,
  });
}

const visibility = useDocumentVisibility();
watch(visibility, (current, previous) => {
  logDevMessage('Layout auth - change vilisibility')
  if (current !== 'visible' || previous !== 'hidden') return;
  setTimeout(async () => {
    await auth.optimisticAuthIfNotYet();
    // if (isLoggedIn.value) {
    //   await goToHome();
    //   return;
    // }
    // TODO: если прошло много времени - показать уведомление, что нужно повторное подтверждение
    // if (!auth.loginToken) {
      //
    // }
  }, 1500)
});

watch(
  isLoggedIn,
  async (newVal) => {
    if (newVal) {
      await goToHome();
    }
  },
  {
    immediate: true,
  },
);
</script>

<template>
  <div class="page-layout">
    <slot />
  </div>
</template>
